import { cls } from '@/utils'
import ReactMarkdown from 'react-markdown'

export interface MarkdownProps {
  className?: string
  children: string
}

const markdownStyle = cls(
  '[&_h1]:text-heading-2xl [&_h1]:font-bold [&_h1]:text-center [&_h1]:my-2',
  '[&_h2]:text-heading-xl [&_h2]:font-bold [&_h2]:text-center [&_h2]:my-2',
  '[&_h3]:text-heading-lg [&_h3]:font-bold [&_h3]:text-center [&_h3]:my-2',
  '[&_h4]:text-heading-md [&_h4]:font-medium [&_h4]:my-2',
  '[&_h5]:text-heading-sm [&_h5]:font-bold [&_h5]:my-2',
  '[&_h6]:text-heading-sm [&_h6]:font-medium [&_h6]:text-center [&_h6]:my-1',
  '[&_img]:max-h-[225px]',
  '[&_p]:my-2',
)

const MarkdownImage = ({ src, alt }: { src: string; alt: string }) => {
  if (src.endsWith('.mp4')) {
    return (
      <div className='flex justify-center'>
        <video autoPlay loop muted playsInline preload='auto' src={src} className='max-w-full max-h-[225px]' />
      </div>
    )
  }
  return (
    <div className='flex justify-center'>
      <img src={src} alt={alt} className='' />
    </div>
  )
}

const MarkdownComponents = {
  img: MarkdownImage,
}

export default function Markdown({ className, children }: MarkdownProps) {
  return (
    <ReactMarkdown className={cls(markdownStyle, className)} components={MarkdownComponents as any}>
      {children}
    </ReactMarkdown>
  )
}
