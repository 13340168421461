import IconBrush from '@haiper/icons-svg/icons/outline/brush.svg'
import IconText from '@haiper/icons-svg/icons/outline/ai-text.svg'
import IconImage from '@haiper/icons-svg/icons/outline/image-square-2-mountains.svg'
import IconClockAdd from '@haiper/icons-svg/icons/outline/clock-add.svg'
import IconFeatures from '@haiper/icons-svg/icons/outline/features.svg'
import { CreationMode, CreationModeEnum, ModelVersion } from '@/types'
import { useMemo } from 'react'

export default function useCreationModes(): {
  data: CreationMode[]
  loading: boolean
} {
  const creationModeOptions = useMemo(() => {
    return [
      {
        mode: CreationModeEnum.CreateImg,
        Icon: IconText,
        iconClassName: 'bg-band-100 text-band-500',
        name: 'Text to Image',
        intro: 'Describe the scene of the video you want',
        tags: ['text'],
        badge: null,
        available: true,
        isNew: false,
        membersOnly: false,
        newFeature: 'Haiper 2.0',
      },
      {
        mode: CreationModeEnum.CreateHD,
        Icon: IconText,
        iconClassName: 'bg-green-600 text-icon-on-color',
        name: 'Text to Video',
        intro: 'Describe any video you can imagine with text',
        tags: ['text'],
        badge: null,
        available: true,
        newFeature: 'Haiper 2.0',
      },
      {
        mode: CreationModeEnum.AnimateHD,
        Icon: IconImage,
        iconClassName: 'bg-band-500',
        name: 'Image to Video',
        intro: 'Upload your own image to bring them to life',
        tags: ['image', 'text'],
        badge: null,
        available: true,
        isUpdated: false,
        newFeature: 'Haiper 2.0',
      },
      {
        mode: CreationModeEnum.Extend,
        Icon: IconClockAdd,
        iconClassName: 'bg-blue-400',
        name: 'Extend Duration',
        intro: 'Extend the duration of your video with simple prompt',
        tags: ['video', 'text'],
        available: true,
        hidden: false,
        isNew: false,
      },
      {
        mode: CreationModeEnum.Upscale,
        Icon: IconFeatures,
        iconClassName: 'bg-red-500',
        name: 'Video Enhancer',
        intro: 'Enhance your video quality with higher resolution and more detail',
        tags: ['video'],
        available: true,
        hidden: false,
        isNew: false,
      },
      {
        mode: CreationModeEnum.Repaint,
        Icon: IconBrush,
        iconClassName: 'bg-yellow-600',
        name: 'Video to Video',
        intro: 'Drag and upload your own images to bring them to life',
        tags: ['video', 'text'],
        available: true,
      },
    ]
  }, [])

  const modeOptions = useMemo(() => {
    return [...creationModeOptions]
  }, [creationModeOptions])

  return {
    data: modeOptions,
    loading: false,
  }
}
