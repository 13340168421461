import useActiveTemplate from '@/hooks/useActiveTemplate'
import { TemplateDetail } from '@/types'
import { cls } from '@/utils'
import Markdown from '@/components/markdown'
import { useAtomValue, useSetAtom } from 'jotai'
import { creationInputAtom, showCreationModeTutorialAtom } from '@/atoms'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import Button from '@/components/button'
import { useCallback } from 'react'
import MailTo from '@/components/mail-to'

export interface CreationModeTutorialProps {
  className?: string
  onDismiss?: () => void
}

export default function CreationModeTutorial({ className, onDismiss }: CreationModeTutorialProps) {
  const { data: template } = useActiveTemplate()
  const tutorial = (template as TemplateDetail)?.tutorial ?? ''
  const showTutorial = tutorial && tutorial.length > 0

  const setShowCreationModeTutorial = useSetAtom(showCreationModeTutorialAtom)

  const hideTutorial = useCallback(() => {
    setShowCreationModeTutorial(false)
    onDismiss?.()
  }, [setShowCreationModeTutorial, onDismiss])

  const creationInput = useAtomValue(creationInputAtom)

  if (!showTutorial || !creationInput?.expanded) return null

  return (
    <div className={cls('relative h-90 flex w-full justify-center bg-surface p-3 pt-0', className)}>
      <div className='bg-surface-base w-full h-full rounded-xl flex justify-center overflow-y-auto no-scrollbar border'>
        <div className='py-3 flex-col h-max'>
          <div className='p-4 pt-2 w-full max-w-100 md:w-100 bg-surface rounded-md text-body-sm'>
            <Markdown>{tutorial}</Markdown>
            <div
              className=' w-full p-4 mt-4 flex flex-col gap-2 bg-surface-subdued rounded-md'
              aria-label='term of use'
            >
              <div className='text-heading-sm font-bold tracking-15'>Terms of Use (Templates)</div>
              <div className='flex flex-col gap-2'>
                <div>
                  Video templates are currently in beta. Videos generated via Templates are not yet available for
                  commercial use.
                </div>
                <div>
                  <span>Please contact</span>
                  <MailTo email='info@haiper.ai' />
                  <span>if you are interested in these videos for commercial use.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button className='size-8 p-0 absolute top-3 right-6 border' onClick={hideTutorial}>
          <IconClose className='size-5 text-icon' />
        </Button>
      </div>
    </div>
  )
}
